import Taleworlds from "../assets/footer/taleworlds.png";
import Aselsan from "../assets/footer/aselsan.png";
import XPG from "../assets/footer/xpg.png";
import DinamoRide from "../assets/footer/dinamoride.jpg";
import SocialThinks from "../assets/footer/socialthinks.png";
import Coderspace from "../assets/footer/coderspace.png";
import SDN from "../assets/footer/sdn.svg";
import Ludenoid from "../assets/footer/ludenoid.png";
import TeknoKroki from "../assets/footer/teknokroki.png";
import TeknoTalk from "../assets/footer/teknotalk.png";
import EtkinKampus from "../assets/footer/etkinkampus.png";
import AjansKurdu from "../assets/footer/ajanskurdu.png";
import BadiWorks from "../assets/footer/badiworks.png";
import BilimSenligi from "../assets/footer/bilimsenligi.png";
import BrandingTurkey from "../assets/footer/brandingturkiye.png";
import IKMagazin from "../assets/footer/ikmagazin.png";
import Robimek from "../assets/footer/robimek.png";
import Univerlist from "../assets/footer/univerlist.png";
import Microfon from "../assets/footer/microfon.png";
import BTHaber from "../assets/footer/bthaber.png";
import GamerPapers from "../assets/footer/gamerpapers.svg";
import Gamizm from "../assets/footer/gamizm.png";
import Webmasto from "../assets/footer/webmasto.png";
import YoviMetro from "../assets/footer/yovimetro.png";
import Algoleague from "../assets/footer/algoleague.png";
import Twitter from "../assets/footer/twitter.svg";
import Linkedin from "../assets/footer/linkedin.svg";
import Instagram from "../assets/footer/instagram.svg";
import h1 from "../assets/pasthuprogs/h1.jpg";
import h2 from "../assets/pasthuprogs/h2.jpg";
import h3 from "../assets/pasthuprogs/h3.jpg";
import h4 from "../assets/pasthuprogs/h4.jpg";
import h5 from "../assets/pasthuprogs/h5.jpg";
import h6 from "../assets/pasthuprogs/h6.jpg";
import FirstPrize from "../assets/details/1.svg";
import SecondPrize from "../assets/details/2.svg";
import ThirdPrize from "../assets/details/3.svg";
import FourthPrize from "../assets/details/4.svg";
import FifthPrize from "../assets/details/5.svg";

export const isTouchDevice = () =>
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

export const DETAILS = [
  {
    color: "pink",
    title: "Kurallar",
    content: (
      <>
        -Hem ön eleme hem de final etabında kendi takımınızın dışındaki herhangi
        birinden yardım almak kesinlikle yasaktır.
        <br />
        <br />
        -Yarışma sırasında kendi kodlarını paylaşan takımlar ve bu kodları
        kullanan takımlar diskalifiye edilecektir.
        <br />
        <br />
        -Takımlar en az 1 en fazla 3 kişiden oluşmalıdır.
        <br />
        <br />
        -Yarışma sırasında internet vb. araçların kullanımında herhangi bir
        kısıtlama yoktur.
        <br />
        <br />
        -Yarışmanın final etabına yarışmacıların kendi bilgisayarlarını
        getirmeleri gerekmektedir.
        <br />
        <br />
        -Yarışmaya katılan kişilerin Türkiye’deki herhangi bir Üniversitede
        lisans veya ön lisans programlarından birinde eğitim görüyor olmaları
        gerekmektedir.
        <br />
        <br />
        -Final etabında yarışacak takımların, etkinlik anında fiziksel olarak
        etkinlik alanında bulunmaları gerekmektedir.
      </>
    ),
  },
  {
    color: "blue",
    title: "Detaylar",
    content: (
      <>
        -Ön eleme 16 Mart'ta online ortamda gerçekleşecek ve 12 saat sürecektir.
        <br />
        <br />
        -Ön eleme saat 12:00'de başlayacaktır.
        <br />
        <br />
        -Ön elemede ilk 20'ye giren takımlar yarışmamızın final ayağına Hacettepe
        Beytepe Kampüsü'ne davet edilecektir.
        <br />
        <br />
        -Final, 23 Mart'ta Beytepe Tunçalp Özgen Kültür ve Kongre Merkezi'nde gerçekleştirilecek ve 16 saat
        sürecektir.
        <br />
        <br />
        -Final etkinliği 17:00'da başlayacak olup yarışma 18:00'da
        başlayacaktır.
        <br />
        <br />
        -Yarışma sonunda ilk beşe giren takımlara ödülleri verilecektir.
      </>
    ),
  },
  {
    color: "orange",
    title: "Yöntem ve İçerik",
    content: (
      <>
        Yarışmanın ön elemesi online olarak algoleague platformu üzerinde
        düzenlenecek olup finale kalan takımların fiziksel katılım koşulu
        aranacaktır.
        <br />
        <br />
        Finale kalan takımlar 16 saat boyunca tarafımızca belirlenen bir alanda
        yarışacaklardır. Yarışma, diğer programlama yarışmalarından da
        alışılagelmiş olduğu üzere, zorlukları ve puanları farklı olan
        problemlerden oluşacaktır.
        <br />
        <br />
        Takımlar bu problemleri istenilen şekilde çözerek, yazdıkları
        programlarla sorular için belirlenen puanları toplayacaktır. Bu süreçte
        yarışmacıların yazdıkları algoritmanın çözebildiği girdi miktarı ve bu
        girdileri belirlenen zaman sınırları içerisinde işleyip çözüme
        ulaşabilmesi dikkate alınacaktır.
        <br />
        <br />
        Puan eşitliği durumunda, o puana en çabuk ulaşan takım daha üst sırada
        kabul edilecektir. Yarışmada en yüksek 5 puana ulaşan takım
        ödüllendirilecek ve finale kalan her takıma, katılımdan dolayı sürpriz
        hediyeler verilecektir.
      </>
    ),
  },
  {
    color: "purple",
    title: "ACM Nedir?",
    content: (
      <>
        ACM (Association for Computing Machinery) 100.000’i aşkın üyesiyle
        alanında dünyanın en büyüğü olma özelliğine sahip, eğitsel ve bilimsel
        bir bilişim topluluğudur.
        <br />
        <br />
        1947 yılında New York’da kurulan ACM, kurulduğu günden bu yana
        yayınladığı dergileri, zengin kütüphanesi ve bünyesinde barındırdığı
        profesyonel kulüpleri, özel araştırma grupları ve öğrenci kollarıyla
        bilgisayar bilimlerinin ilerlemesine öncülük etmektedir.
        <br />
        <br />
        ACM Hacettepe Öğrenci Topluluğu, ACM’e bağlı dünya üzerindeki 821,
        Türkiye’deki 13 öğrenci kolundan birisidir. 2010 yılında kurulan
        topluluğumuz, kurulduğu günden bu yana öncelikle Hacettepe Üniversitesi
        öğrencileri olmak üzere ulaşabildiği tüm öğrenciler için bilgisayar
        bilimlerine ilgiyi artıracak ve bu alanda farkındalık yaratacak
        etkinlikler düzenlemektedir. Bunun yanında sadece bilişim alanında
        kısıtlı kalmayıp aynı hedef kitlesinin sosyal bir birey olarak
        gelişimine katkı sağlamayı da kendimize görev bilmekteyiz.
      </>
    ),
  },
];

export const SOCIALS = [
  {
    link: "https://twitter.com/acmhacettepe",
    image: Twitter,
  },
  {
    link: "https://www.linkedin.com/company/acm-hacettepe",
    image: Linkedin,
  },
  {
    link: "https://www.instagram.com/acmhacettepe",
    image: Instagram,
  },
];

export const DETAILEDSPONSORS = [
  {
    title: "Dönem Ana Sponsorumuz",
    link: "https://www.taleworlds.com/",
    image: Taleworlds,
  },
  {
    title: "Gümüş Sponsorumuz",
    link: "https://www.aselsan.com/tr",
    image: Aselsan,
  },
  {
    title: "Gümüş Sponsorumuz",
    link: "https://www.xpg.com/TR",
    image: XPG,
  },
  {
    title: "Gümüş Sponsorumuz",
    link: "https://dinamoride.com/",
    image: DinamoRide,
  },
  {
    title: "Platform Sponsorumuz",
    link: "https://algoleague.com/",
    image: Algoleague,
  },
];

export const MEDIASPONSORS = [
  {
    link: "https://www.yovi.com.tr/",
    image: YoviMetro,
  },
  {
    link: "https://www.socialthinks.com/tr",
    image: SocialThinks,
  },
  {
    link: "https://coderspace.io/",
    image: Coderspace,
  },
  {
    link: "https://shiftdelete.net/",
    image: SDN,
  },
  {
    link: "https://www.ludenoid.com/",
    image: Ludenoid,
  },
  {
    link: "https://www.teknokroki.com/",
    image: TeknoKroki,
  },
  {
    link: "https://www.teknotalk.com/",
    image: TeknoTalk,
  },
  {
    link: "https://www.etkinkampus.com/",
    image: EtkinKampus,
  },
  {
    link: "https://ajanskurdu.com/",
    image: AjansKurdu,
  },
  {
    link: "https://badiworks.com/",
    image: BadiWorks,
  },
  {
    link: "https://www.bilimsenligi.com/",
    image: BilimSenligi,
  },
  {
    link: "https://www.brandingturkiye.com/",
    image: BrandingTurkey,
  },
  {
    link: "https://ikmagazin.com/",
    image: IKMagazin,
  },
  {
    link: "https://www.robimek.com/yeni/",
    image: Robimek,
  },
  {
    link: "https://univerlist.com/",
    image: Univerlist,
  },
  {
    link: "https://microfon.co/",
    image: Microfon,
  },
  {
    link: "https://www.bthaber.com/",
    image: BTHaber,
  },
  {
    link: "https://gamerpapers.com/",
    image: GamerPapers,
  },
  {
    link: "https://gamizm.com/",
    image: Gamizm,
  },
  {
    link: "https://webmasto.com/",
    image: Webmasto,
  },
];

export const PASTHUPROGS = [
  { img: h1 },
  { img: h2 },
  { img: h3 },
  { img: h4 },
  { img: h5 },
  { img: h6 },
];

export const PRIZES = [
  { img: FirstPrize },
  { img: SecondPrize },
  { img: ThirdPrize },
  { img: FourthPrize },
  { img: FifthPrize },
];

export const COLORS = {
  mainBackground: "#0F0F17",
  secondaryBackground: "#151521",
  secondaryBackgroundLight: "#191927",
  paragraphColor: "#686884",
  purple: "#8559E8",
  blue: "#5A60FE",
  pink: "#C54BC9",
  orange: "#FF7765",
  darkPurple: "#140F27",
  paleGray: "#6C6C93",
  border: "#222233",
  gradient:
    "-webkit-linear-gradient(10deg, rgb(102 84 255) 0%, rgb(255 76 234) 43%, rgb(255 92 70) 100%)",
};

export const FONT_WEIGHTS = {
  regular: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
  extrabold: "900",
};
