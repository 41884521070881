import * as sc from "../styled/scCounter";
import React, { useState, useRef, useEffect, useCallback } from "react";

const Counter = ({ className }) => {
  const ref = useRef(null);

  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    const days = Math.floor((total / 1000 / 60 / 60 / 24) % 30);

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = useCallback((e) => {
    const { total, days, hours, minutes, seconds } = getTimeRemaining(e);

    if (total >= 0) {
      setTimerDays(days > 9 ? days : "0" + days);
      setTimerHours(hours > 9 ? hours : "0" + hours);
      setTimerMinutes(minutes > 9 ? minutes : "0" + minutes);
      setTimerSeconds(seconds > 9 ? seconds : "0" + seconds);
    }
  }, []);

  const getStartDate = () => {
    let deadline = new Date("16 March, 2024 12:00:00");
    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  };

  useEffect(() => {
    setTimerDays("00");
    setTimerHours("00");
    setTimerMinutes("00");

    if (ref.current) {
      clearInterval(ref.current);
    }

    const interval = setInterval(() => startTimer(getStartDate()), 1000);
    ref.current = interval;

    return () => {
      clearInterval(interval);
    };
  }, [startTimer]);

  return (
    <sc.CounterWrapper className={className}>
      <sc.CounterContainer>
        <sc.ElementContainer>
          <strong className="blue">{timerDays}</strong>
          <span>Gün</span>
        </sc.ElementContainer>
        <sc.ElementContainer>
          <strong className="purple">{timerHours}</strong>
          <span>Saat</span>
        </sc.ElementContainer>
        <sc.ElementContainer>
          <strong className="pink">{timerMinutes}</strong>
          <span>Dakika</span>
        </sc.ElementContainer>
        <sc.ElementContainer>
          <strong className="orange">{timerSeconds}</strong>
          <span>Saniye</span>
        </sc.ElementContainer>
      </sc.CounterContainer>
    </sc.CounterWrapper>
  );
};

export default Counter;
