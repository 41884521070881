import * as sc from "../styled/scInfo";
import RightArrow from "../assets/common/rightArrow.svg";
import InfoImage from "../assets/info/infoImage.svg";
import InfoImage2 from "../assets/info/infoImage2.svg";
import InfoImage3 from "../assets/info/infoImage3.svg";

const Info = () => (
  <sc.InfoWrapper className="info-wrapper">
    <sc.DetailsContainer>
      <sc.Title className="info-animated">
        Yarışma <span>Detayları</span>
      </sc.Title>
      <sc.Details className="info-animated">
        HUPROG'24 Türkiye'deki tüm öğrencilere açık olan ve finalinin 23
        Mart'ta Hacettepe Üniversitesi Beytepe Tunçalp Özgen Kültür ve Kongre Merkezi'nde
        gerçekleştirilmesi planlanan bir programlama yarışmasıdır.
      </sc.Details>
    </sc.DetailsContainer>
    <sc.CardsContainer className="info-animated">
      <sc.Card>
        <sc.CardOverlay className="info-card-overlay" />
        <sc.CardHeading>Final Etabı</sc.CardHeading>
        <sc.CardDescription>23.03.2024 - 18:00</sc.CardDescription>
        <sc.CardImage src={InfoImage} alt="" />
      </sc.Card>
      <sc.Card>
        <sc.CardOverlay className="info-card-overlay" />
        <sc.CardHeading>Ön Eleme</sc.CardHeading>
        <sc.CardDescription>16.03.2024 - 12:00</sc.CardDescription>
        <sc.CardImage src={InfoImage2} alt="" />
      </sc.Card>
      <sc.Card>
        <sc.CardOverlay className="info-card-overlay" />
        <sc.CardHeading>Toplam Ödül</sc.CardHeading>
        <sc.CardDescription>80.000₺</sc.CardDescription>
        <sc.CardImage src={InfoImage3} alt="" />
      </sc.Card>
    </sc.CardsContainer>
    <sc.ButtonContainer className="info-animated">
      <sc.ApplyButton
        href="https://forms.gle/E9d4347j6eQZUYxb8"
        target="_blank"
      >
        Başvur
        <img src={RightArrow} alt="Başvur" />
      </sc.ApplyButton>
    </sc.ButtonContainer>
  </sc.InfoWrapper>
);

export default Info;
