import * as sc from "../styled/scFooter";
import FooterBackground from "../assets/footer/footerBackground.svg";
import AcmHacettepe from "../assets/footer/acmhacettepe.png";
import { SOCIALS, DETAILEDSPONSORS, MEDIASPONSORS } from "../utils";

const Footer = () => (
  <>
    <sc.SponsorsWrapper className="sponsors-wrapper">
      <sc.SponsorsContainer>
        <sc.SponsorsContainerLeft>
          {DETAILEDSPONSORS.map(({ title, link, image, bgColor }, i) => (
            <sc.DetailedSponsor key={i}>
              <sc.DetailedSponsorTitle className="sponsors-animated">
                {title}
              </sc.DetailedSponsorTitle>
              <sc.Sponsor
                href={link}
                target="_blank"
                className="sponsors-animated"
                $bgColor={bgColor}
                $detailedSponsor
              >
                <sc.SponsorImage src={image} />
              </sc.Sponsor>
            </sc.DetailedSponsor>
          ))}
        </sc.SponsorsContainerLeft>
        <sc.SponsorsContainerRight>
          <sc.SponsorsHeadingRight className="sponsors-animated">
            Medya Sponsorlarımız
          </sc.SponsorsHeadingRight>
          <sc.SponsorsRight>
            {MEDIASPONSORS.map(({ link, image }, i) => (
              <sc.Sponsor
                key={i}
                href={link}
                target="_blank"
                className="sponsors-animated"
              >
                <sc.SponsorImage src={image} />
              </sc.Sponsor>
            ))}
          </sc.SponsorsRight>
        </sc.SponsorsContainerRight>
      </sc.SponsorsContainer>
    </sc.SponsorsWrapper>
    <sc.FooterWrapper image={FooterBackground}>
      <sc.FooterContainer>
        <sc.FooterImage src={AcmHacettepe} />
        <sc.FooterText>
          Etkinlik hakkında herhangi bir istek, öneriniz veya merak ettikleriniz
          hakkında bize mail yolu ile ulaşabilirsiniz.
        </sc.FooterText>
        <sc.FooterButton
          href="https://www.acmhacettepe.com/iletisim"
          target="_blank"
        >
          İletişim
        </sc.FooterButton>
        <sc.FooterSocialGroup>
          {SOCIALS.map(({ link, image }, i) => (
            <sc.FooterSocialButton key={i} href={link} target="_blank">
              <sc.FooterSocialImage src={image} />
            </sc.FooterSocialButton>
          ))}
        </sc.FooterSocialGroup>
      </sc.FooterContainer>
    </sc.FooterWrapper>
  </>
);

export default Footer;
