import { CodeEditor } from "../assets/dynamicAssets";
import * as sc from "../styled/scHero";
import Counter from "./Counter";
import HeroBackground from "../assets/hero/heroBackground.svg";

const Hero = () => (
  <sc.HeroWrapper image={HeroBackground}>
    <sc.HeroContainer>
      <sc.HeroDescription className="hero-animated">
        ACM Hacettepe Sunar
      </sc.HeroDescription>
      <sc.HeroHeading className="hero-animated">
        HU<span>PROG'24</span>
      </sc.HeroHeading>
      <Counter className="hero-animated" />
      <CodeEditor />
    </sc.HeroContainer>
  </sc.HeroWrapper>
);

export default Hero;
